.react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
    &:checked + .react-switch-label .react-switch-button {
      left: 100%;
      transform: translateX(-100%);
    }
  }
  .react-switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 250px;
    height: 35px;
    background: white;
    border-color: #00a3ff;
    border-style: solid;
    border-width: thin;
    border-radius: 3px;
    position: relative;
    transition: background-color 0.2s;
    &:active .react-switch-button {
      width: 60px;
    }
    & .react-switch-button {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      border-radius: 3px;
      transition: 0.2s;
      background: #00a3ff;
    }
  }
  
  
  