* {
  box-sizing: border-box;
  font-optical-sizing: auto;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;

}

.app-nav {
  height: 60px;
}
.app-nav:hover {
  background-color: #00a3ff;
  cursor: pointer;
}

.app-container {
  background-color: #8e8d8a;
  min-height: calc(100vh - 178px);
  display: flex;
  width: 100%;
  flex: 1;
}

.app-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0 2rem 0; 
}

.app-content {
  flex-grow: 1;
  overflow: hidden;
  max-width: 1400px !important;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
 

  flex: 1;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.left-bar {
  display: block;
  height: auto;
  min-height: calc(100vh - 178px);
  background-color: #382c7c;
  width: 60px;
  color: #fff;
}


::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.dialog-size {
  width: 50%;
}



@media only screen and (max-width: 768px) {
  .left-bar {
    display: none;
  }

  .app-container {
    width: 100%;
  }

  .dialog-size {
    width: 100%;
  }
}
